import { ARCHIVE_WIDGET_NAME } from '../viewer-script/constants/widgets';
import { withViewer } from '../common/hoc/with-viewer';
import AppRoot from './containers/app-root';

const isProduction = process.env.NODE_ENV === 'production';
const stylesConfig = {
  ltrCssPath: isProduction ? 'archive-widget-viewer.min.css' : 'archive-widget-viewer.css',
  rtlCssPath: isProduction ? 'archive-widget-viewer.rtl.min.css' : 'archive-widget-viewer.rtl.css',
};

export default withViewer(AppRoot, stylesConfig, ARCHIVE_WIDGET_NAME);
